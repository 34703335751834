import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  MappedQuestionContentAnswer,
  QuestionContent,
  QuestionContentAnswer,
  QuestionValidation,
} from '../../types/questions.types';
import ErrorMessageComponent from '../errors/error-message/error-message.component';
import CheckRow from './check-row.component';
import { multiselectWrapper } from './style';

export type MultiselectPropTypes = {
  inputId?: string;
  isValidationChecked: boolean; // isChecked
  isMapped?: boolean;
  validation: QuestionValidation;
  onChange: (
    selectedAnswers: QuestionContentAnswer[],
    isValid: boolean
  ) => void; // handleChoiceAnswer, this should be typed by QuestionComponent
  className?: string;
  questionContent: QuestionContent;
  selectedAnswers: QuestionContent['answers']; // comes from getSelectedValueForQuestion for now

  // these should be removed as mapped questions are cleaned up
  actions?: any;
  onAnswer?: () => void; // question-mapped.component only, this needs to go away
};

const DEFAULT_MINIMUM_ANSWERS = 1;
const DEFAULT_MAXIMUM_ANSWERS = 10;
export const NONE_OF_THE_ABOVE: QuestionContentAnswer = {
  id: null,
  score: null,
  text: 'none',
};

export const validateAnswers = (
  numberOfAnswers: number,
  minimumAnswers: number,
  maximumAnswers: number
) => {
  // not enough answers selected
  if (numberOfAnswers < minimumAnswers) {
    const answersWording = minimumAnswers === 1 ? 'answer' : 'answers';
    return `You must select at least ${minimumAnswers} ${answersWording}`;
  }

  // too many answers selected
  if (numberOfAnswers > maximumAnswers) {
    const answersWording = maximumAnswers === 1 ? 'answer' : 'answers';
    return `You can't select more than ${maximumAnswers} ${answersWording}`;
  }

  // no errors
  return '';
};

export const getNewAnswers = (
  toggledAnswer: QuestionContentAnswer | MappedQuestionContentAnswer,
  oldAnswers: QuestionContent['answers']
) => {
  const existingItemIndex = oldAnswers.findIndex(
    ans => ans.id === toggledAnswer.id
  );

  // special logic for None of the Above option to wipe the rest if None was not already selected
  const userSelectedNoneOfAbove =
    toggledAnswer.id === NONE_OF_THE_ABOVE.id && existingItemIndex === -1;

  if (userSelectedNoneOfAbove) {
    return [NONE_OF_THE_ABOVE];
  }

  const newAnswers =
    existingItemIndex > -1
      ? [
          // remove it if it's there now
          ...oldAnswers.slice(0, existingItemIndex),
          ...oldAnswers.slice(existingItemIndex + 1),
        ]
      : [...oldAnswers, toggledAnswer]; // add it if it's not there now

  // the user selected something that _wasn't_ none of the above, so make sure to filter it out
  const filterNoneOption = newAnswers.filter(
    ans => ans.id !== NONE_OF_THE_ABOVE.id
  );

  return filterNoneOption;
};

const Multiselect: FunctionComponent<MultiselectPropTypes> = ({
  inputId,
  isValidationChecked,
  isMapped = false,
  validation,
  onChange,
  selectedAnswers,
  questionContent,
  actions,
  onAnswer,
}) => {
  const [errorText, setErrorText] = useState('');
  const [activeDecendentId, setActiveDecendentId] = useState<
    string | undefined
  >(undefined);
  const minimumAnswers = validation.minimumAnswers ?? DEFAULT_MINIMUM_ANSWERS;
  const maximumAnswers = validation.maximumAnswers ?? DEFAULT_MAXIMUM_ANSWERS;
  const questionReferenceAnswers = questionContent.answers;
  const noneOfTheAboveLabelText = questionContent.show_none_of_the_above;
  const numberOfAnswers = selectedAnswers.length;

  useEffect(() => {
    setErrorText(
      validateAnswers(numberOfAnswers, minimumAnswers, maximumAnswers)
    );
    // initial render validation only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maximumAnswers, minimumAnswers]);

  const validate = useCallback(
    (numberOfAnswers: number) => {
      const errorMessage = validateAnswers(
        numberOfAnswers,
        minimumAnswers,
        maximumAnswers
      );
      setErrorText(errorMessage);

      return !errorMessage;
    },
    [maximumAnswers, minimumAnswers]
  );

  const handleCheck = useCallback(
    (
      toggledAnswer: QuestionContentAnswer | MappedQuestionContentAnswer,
      newActiveDecendentId?: string
    ) => {
      setActiveDecendentId(newActiveDecendentId);
      const newAnswers = getNewAnswers(toggledAnswer, selectedAnswers);
      const isValid = validate(newAnswers.length);

      onChange(newAnswers, isValid);
    },
    [validate, onChange, selectedAnswers]
  );

  return (
    <div
      css={multiselectWrapper}
      aria-labelledby={`${inputId}_title`}
      aria-activedescendant={activeDecendentId}
    >
      {questionReferenceAnswers.map((referenceAnswer, index) => {
        const isRowSelected = (
          selected: QuestionContentAnswer | MappedQuestionContentAnswer
        ) => referenceAnswer.id === selected.id;
        return (
          <CheckRow
            referenceAnswer={referenceAnswer}
            key={`${referenceAnswer.id}-${index}`}
            isMapped={isMapped}
            question_id={questionContent.question_id}
            isChecked={!!selectedAnswers.find(isRowSelected)}
            onChange={handleCheck}
            actions={actions}
            belongsTo={`${questionContent.question_id}-${questionContent.id}-${questionContent.lang}-option-${index}`}
            onAnswer={onAnswer}
          />
        );
      })}
      {noneOfTheAboveLabelText && (
        <CheckRow
          label={noneOfTheAboveLabelText}
          referenceAnswer={NONE_OF_THE_ABOVE}
          key={`none-of-the-above`}
          isMapped={false}
          question_id={questionContent.question_id}
          isChecked={
            !!selectedAnswers.find(el => el.id === NONE_OF_THE_ABOVE.id)
          }
          onChange={handleCheck}
          actions={actions}
          belongsTo={`${questionContent.question_id}-${questionContent.id}-${questionContent.lang}`}
          onAnswer={onAnswer}
        />
      )}
      {isValidationChecked && errorText && (
        <ErrorMessageComponent errorMessage={errorText} />
      )}
    </div>
  );
};

export default memo(Multiselect);
